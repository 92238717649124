import type { GaVueComponent } from "@/common/vueUtils";
import type { DeepReadonly } from "vue";

type Props = {
  value: string;
  onUpdateValue: (newValue: string) => void;
  label?: string;
  name: string;
  placeholder?: string;
  rows?: number;
  help?: string;
  errors?: DeepReadonly<string[]>;
};

export function GaFormFieldTextArea(props: Props): GaVueComponent {
  const errors = props.errors ?? [];

  return (
    <div>
      {props.label !== undefined && props.label.trim().length > 0 ? (
        <label for={props.name} class="form-label">
          {props.label}
        </label>
      ) : null}
      <textarea
        class={{
          "form-control": true,
          "is-invalid": errors.length > 0,
        }}
        rows={props.rows}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onInput={(e) => props.onUpdateValue((e.target as HTMLInputElement).value)}
      />
      {errors.map((error) => (
        <div class="invalid-feedback">{error}</div>
      ))}
      {props.help !== undefined && props.help.trim().length > 0 ? <div class="form-text">{props.help}</div> : null}
    </div>
  );
}
